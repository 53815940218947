import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from "../../app/store";
import {fetchObligations, listObligations} from "./obligationsAPI";

export interface ObligationState {
    value: Array<Obligation>;
    status: 'idle' | 'loading' | 'failed' | 'fulfilled';
    submission : ISubmission;
}

export interface Obligation {
    periodKey: string;
    start: string;
    end: string;
    due: string;
    status: string;
    received: string;
}

export class Submission {
    box_1 : string = '0.00';
    box_2 : string = '0.00';
    box_3 : string = '0.00';
    box_4 : string = '0.00';
    box_5 : string = '0.00';
    box_6 : string = '0.00';
    box_7 : string = '0.00';
    box_8 : string = '0.00';
    box_9 : string = '0.00';
}
export interface ISubmission {
    box_1 : string;
    box_2 : string;
    box_3 : string;
    box_4 : string;
    box_5 : string;
    box_6 : string;
    box_7 : string;
    box_8 : string;
    box_9 : string;
}

const initialState: ObligationState = {
    value: [
        //{periodKey: 'FG01', start: '02032001', end: '03032001', due: '08032001', status: 'C', received: 'Y'},
        //{periodKey: 'FG02', start: '02042001', end: '03042001', due: '08042001', status: 'O', received: 'N'},
    ],
    status: 'idle',
    submission: {} as ISubmission
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchObligationsAsynch = createAsyncThunk(
    'obligations/fetchObligations',
    async () => {
        const stubbed : boolean = false;
        if (stubbed) {
            const response = await fetchObligations();
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } else {
            const response = await listObligations();
            console.log("Obligations back from HMRC -- Response: "+response.responseCode+" , Obligations: " +response.obligations);
            return response.obligations;
        }
    }
);



export const obligationsSlice = createSlice({
    name: 'obligations',
    initialState,
    reducers: {
        obligationAdded: (state, action: PayloadAction<Obligation>) => {
            //state.value = [...state.value, action.payload];
            state.value.push(action.payload);
        },
        obligationsReplaced: (state, action: PayloadAction<Obligation[]>) => {
            //state.value = action.payload.slice(0);
            state.value = action.payload;
            //state.value.splice(0, state.value.length, ...action.payload);
        },
        obligationSubmitted: (state, action: PayloadAction<Obligation>) => {
            //TODO Do Something
        },
        fileParsed: (state, action: PayloadAction<ISubmission>) => {
            state.submission = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchObligationsAsynch.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchObligationsAsynch.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.value = action.payload;
            });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectObligations = (state: RootState) => state.obligations.value;
export const selectObligationsStatus = (state: RootState) => state.obligations.status;
export const selectParsedFileSubmission = (state: RootState) => state.obligations.submission;


export const { obligationAdded, obligationsReplaced, obligationSubmitted, fileParsed } = obligationsSlice.actions;


export default obligationsSlice.reducer;